<template>
  <div class="article-wrap">
    <div class="article-top-tips" v-if="browserEnv.isWeiXin && !browserEnv.isIOS">
      <img class="article-top-tips-img" :src="configInfo.wx_tips_src" />
    </div>
    <div class="article-text">
      <div class="article-text-title">
        <img
          class="article-text-logo"
          src="https://xcx.alicdn2.hexiaoxiang.com/h5/page_config/imgs/shu-tong-logo.png"
        />
        小书童
      </div>
      <div class="article-text-desc">{{ configInfo.description }}</div>
    </div>
    <div @click="downloadHandle" class="article-action">
      <img class="article-action-img" :src="configInfo.action_src" />
    </div>
    <div class="article-banner">
      <img class="article-banner-img" :src="configInfo.banner_src" />
    </div>
    <div class="article-more" v-if="configInfo.more && configInfo.more.length">
      <div class="article-more-title">更多提升课程</div>
      <div class="article-more-list">
        <a
          class="article-more-item"
          v-for="(item, index) in configInfo.more"
          :key="index"
          :href="item.link"
        >
          <img class="article-more-logo" :src="item.icon" />
          <div>{{ item.name }}</div>
        </a>
      </div>
    </div>

    <el-dialog title custom-class="dialog-wrap" :show-close="false" :visible.sync="isVisible">
      <span class="dialog-body">点击右上角···按钮，然后在弹出的菜单中点击"在浏览器打开"，即可安装</span>
      <span slot="footer" class="dialog-footer" @click="isVisible = false">
        <!-- <el-button type="primary" @click="isVisible = false">我知道了</el-button> -->
        我知道了
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { createCNZZScript } from '../core/cnzz.js'

window._czc = []

export default {
  components: {},
  data() {
    return {
      configInfo: {},
      isVisible: false
    };
  },
  computed: {
    browserEnv() {
      return {
        isPhone: Boolean(
          /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)
        ),
        isWeiXin: Boolean(navigator.userAgent.match(/MicroMessenger/gi)),
        isMiniProgram: Boolean(navigator.userAgent.match(/miniProgram/gi)),
        isAli: Boolean(navigator.userAgent.match(/AlipayClient/gi)),
        isAndroid: Boolean(navigator.userAgent.match(/android/gi)),
        isIPhone: Boolean(navigator.userAgent.match(/iphone|ipod/gi)),
        isIPad: Boolean(navigator.userAgent.match(/ipad|Macintosh/gi)),
        isIOS: Boolean(
          navigator.userAgent.match(/iphone|ipod|ipad|Macintosh/gi)
        )
      };
    }
  },
  created() {
    this.getConfigData()
    _czc = createCNZZScript()
  },
  methods: {
    getConfigData() {
      this.axios
        .get(
          "https://xcx.alicdn2.hexiaoxiang.com/h5/page_config/shu-tong-config.json"
        )
        .then(res => {
          this.configInfo = res.data.data;
        });
    },
    downloadHandle() {
      this.czcDownloadBtnPV()
      if (this.browserEnv.isIOS) {
        location.href = this.configInfo.ios_link;
      } else {
        if (this.browserEnv.isWeiXin) {
          this.isVisible = true;
        } else {
          this.czcDownloadPV()
          location.href = this.configInfo.android_link;
        }
      }
    },
    // 访问下载资源
    czcDownloadPV() {
      _czc.push(['_trackPageview', '/apk/xiaoshutong/xiaoshutong.apk', 'https://xcx.alicdn2.hexiaoxiang.com/'])
    },
    // 点击下载按钮
    czcDownloadBtnPV() {
      /**
      事件编号	category	action  	label       	value            	nodeid
        1    	小书童	   download	  referSource	  ?referSource=0	无
      */
      _czc.push(['_trackEvent','小书童', '下载', 'referSource', +this.$route.query.referSource]);
    },
  }
};
</script>
<style lang="scss">
.minWidth {
  min-width: 0;
}
.article-wrap {
  .el-dialog__footer {
    border-top: 1px solid #eee;
  }
  .el-dialog__header {
    padding: 0;
  }
  .dialog-wrap {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    margin: 0 !important;
    border-radius: 15px;
  }
}
</style>
<style lang="scss" scoped>
.article-wrap {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  background: url("https://xcx.alicdn2.hexiaoxiang.com/h5/page_config/imgs/download-bg.jpg")
    center center no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  overflow: hidden;
  font-size: 16px;
}
.article-top-tips {
  position: relative;
  // height: 70px;
  padding-top: 20%;
}
.article-top-tips-img {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 750px;
}
.article-text {
  margin: 35px 20px 20px;
  overflow: hidden;
  .article-text-title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 25px;
  }
  .article-text-logo {
    width: 60px;
    vertical-align: middle;
  }
  .article-text-desc {
    font-size: 16px;
    line-height: 1.45em;
    font-weight: 100;
    text-align: justify;
    text-indent: 2em;
  }
}
.article-banner {
  margin: 0 20px 30px 35px;
  overflow: hidden;

  .article-banner-img {
    width: 100%;
    display: block;
  }
}
.article-action {
  display: block;
  width: 180px;
  margin: 0 auto 30px;
  overflow: hidden;
}
.article-action-img {
  width: 100%;
}
.article-more {
  padding-bottom: 60px;
  .article-more-title {
    padding: 40px 0;
    font-size: 15px;
    text-align: center;
  }
  .article-more-list {
    display: flex;
    justify-content: space-around;
  }
  .article-more-item {
    display: block;
    font-size: 10px;
    text-align: center;
    color: #333;
  }
  .article-more-logo {
    width: 60px;
    height: 60px;
  }
}
.dialog-footer {
  display: block;
  width: 100%;
  color: #1989fa;
  text-align: center;
}
.dialog-body {
  font-size: 16px;
}
</style>
